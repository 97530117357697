import React from "react";
import {Col, Container, Input, Row, UncontrolledTooltip} from "reactstrap";
import classnames from "classnames";
import {TiWarning} from "react-icons/all";
import WhoisResultAvailable from "./WhoisResultAvailable";
import WhoisResultNotAvailable from "./WhoisResultNotAvailable";
import {Whois} from "../lib/Whois";
import ButtonLoading from "./ButtonLoading";
import {parseDomain} from "parse-domain";
import {toast} from "react-toastify";
import WhoisSuggestion from "./WhoisSuggestion";
import {getSiteConfig, set_site_title} from "../App";
import WhoisTlds from "./WhoisTlds";

const punycode = require('punycode');

export function clearDomain(a, e) {
    if (void 0 === a) return !1;
    a = (a = (a = a.replace("http://", "").replace("HTTP://", "")).replace("https://", "").replace("HTTPS://", "")).replace("www.", "").replace("WWW.", "");
    const specialChars = ['$', '^', '_', '!', '?', '_', '*', '=', '&', '%', '+', '/', '(', ')', '<', '>'];
    let lastChar = '', aArray = a.split("");
    for (let i = 0; i < aArray.length; i++) {
        if ((lastChar === '-' && aArray[i] === '-') || specialChars.indexOf(aArray[i]) > -1)
            aArray[i] = '';
        lastChar = aArray[i];
    }

    return aArray.join("")
        // .replace(/[^a-z0-9-ÃƒÂ§ÃƒÂ¶Ã…Å¸ÃƒÂ¼Ã„Å¸Ã„Â±.]/gi, " ")
        .replace(/\s+/g, "-")
        .toLowerCase();
}


class HomeWhois extends React.Component {
    state = {
        queryDuring: false,
        showFirst: 0,
        showLast: 2,
        pause: false,
        inputDomain: this.initDomain || "",
        whoisDomain: null,
        whoisResult: null,
        registerUrl: "",
        whoisData: {},
        idnDomain: "",
        tld: "",
        validDomain: true
    }

    timer = null
    inputDiv = null

    domainEdit = (e) => {
        let entered_domain = punycode.toASCII(e.target.value);
        let dom = parseDomain(entered_domain);
        if (typeof dom === "object" && typeof dom.topLevelDomains === "object")
            this.setState({tld: dom.topLevelDomains.join(".")});
        let validDomain =
            entered_domain === "" || // empty input
            (typeof dom === "object" && dom.type === "LISTED") || // standard full domain
            (typeof dom === "object" && dom.type === "NOT_LISTED" && entered_domain.indexOf(".") === -1) // no include dot symbol and tld
        let state_changed = {
            validDomain: validDomain
        };


        if (typeof dom === "object" && dom.type === "NOT_LISTED" && entered_domain.indexOf(".") === -1) {
            state_changed['inputDomain'] = entered_domain + '.com.tr';
        }

        this.setState(state_changed)
    }

    whoisDomainInput = null;
    whoisResultDiv = null;
    initDomain = null;

    constructor(props) {
        super(props);
        if (typeof props === "object" && typeof props.match === "object")
            this.initDomain = typeof props.match.params === "object" && typeof props.match.params.domain === "string" ? props.match.params.domain : null;
        set_site_title(this.initDomain);
    }

    componentDidMount() {
        let self = this

        let dnInput = document.getElementById('domain-input');
        const resetQuery = () => {
            dnInput.focus();
            dnInput.setSelectionRange(0, dnInput.value.length);
            window.scrollTo(0, self.whoisDomainInput.offsetTop);
        };

        const resetResults = () => {
            this.setState({whoisResult: null})
        }

        window.addEventListener("message", function (ev) {
            let {data} = ev;
            if (data.command === "refresh_query" && self.whoisDomainInput) {
                resetQuery();
            } else if (data.command === "registrant_null") {
                toast.error("Sahip bilgisi bulunamadı!", {
                    position: "top-center"
                });
            } else if (data.command === "whois_responded" && self.whoisResultDiv) {
                window.scrollTo(0, self.whoisResultDiv.offsetTop - 100);
                let {domain} = data;
                window.history.pushState({}, null, window.location.origin + '/' + (domain));
                set_site_title(domain);
            } else if (data.command === "whois_reset") {
                resetQuery();
                resetResults();
                set_site_title("");
                dnInput.value = "";
            }
        });

        if (this.initDomain !== null) {
            this.whoisProcess(this.initDomain, false, false, null);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    domainInputKeyup = (e) => {
        let dominput = e.target;
        let val = clearDomain(dominput.value, 0);
        this.setState({inputDomain: val})
    }

    whoisProcess = (dn, force, ref, callback) => {

        if (typeof force === "undefined")
            force = false;

        if (typeof ref === "undefined")
            ref = false;

        this.setState({
            queryDuring: true
        });

        Whois.whois(dn, force, ref).then(r => {
            if (typeof r.Status === "string" && r.Status === "available") {
                this.setState({
                    whoisDomain: r.Domain,
                    whoisResult: true,
                    registerUrl: r.RegisterUrl,
                    queryDuring: false
                }, () => {
                    if (typeof callback === "function") {
                        callback();
                    } else {
                        window.postMessage({command: "whois_responded", domain: r.Domain}, "*")
                    }
                })
            } else {
                if (ref) {
                    if (r.registrant === null || Object.keys(r.registrant).length === 0) {
                        window.postMessage({command: "registrant_null", domain: r.domain}, "*")
                    } else {
                        window.postMessage({command: "registrant_data_loaded", domain: r.domain}, "*")
                    }
                }

                this.setState({
                    whoisDomain: r.domain,
                    whoisResult: false,
                    whoisData: r,
                    registerUrl: "",
                    queryDuring: false
                }, () => {
                    if (typeof callback === "function") {
                        callback();
                    } else {
                        window.postMessage({command: "whois_responded", domain: r.domain}, "*")
                    }
                })
            }
        }).catch(err => {
            toast.error(err);
            this.setState({queryDuring: false}, () => {

            })
        });
    }

    render() {
        let data = this.state.whoisData;
        let {Price} = data;
        return <>
            <div className={"homepage-whois w-100 overflow-hidden p-2 p-lg-0"}>
                <Row>
                    <Col className={"text-center"} sm={{size: 10, offset: 1}} md={{size: 8, offset: 2}}>
                        <h1 className={"text-white"}>{getSiteConfig('home-title')}</h1>
                        <h5 className={"text-white"}>{getSiteConfig("home-description")}</h5>
                        <form className={"position-relative"} onSubmit={async (e) => {
                            e.preventDefault();
                            await this.domainEdit({
                                target: {value: this.state.inputDomain}
                            });
                            if (!this.state.validDomain) {
                                toast.error("Lütfen geçerli bir domain giriniz.");
                                return false;
                            }
                            this.whoisProcess(this.state.inputDomain, false)
                        }}>
                            {!this.state.validDomain &&
                            <><UncontrolledTooltip target={"invalid-domain-warning"}>Domain uygun
                                görünmüyor!</UncontrolledTooltip>
                                <TiWarning id={"invalid-domain-warning"} size={27}/>
                            </>}
                            <div className="d-flex" ref={div => this.inputDiv = div}>
                                <Input id={"domain-input"}
                                       inputmode={"url"}
                                       className={classnames("domain-input", {
                                           "in-error": !this.state.validDomain
                                       })}
                                       ref={(inp) => this.whoisDomainInput = inp}
                                       onBlur={(e) => this.domainEdit(e)}
                                       onChange={this.domainInputKeyup}
                                       value={this.state.inputDomain}
                                />
                                <ButtonLoading
                                    type={"submit"}
                                    text={"Ara"}
                                    loading={this.state.queryDuring}
                                    color={getSiteConfig("search-button-color")||"secondary"}/>
                            </div>
                        </form>
                        <WhoisTlds useTld={(tld) => {
                            let newDomain = (this.state.inputDomain).split(".")[0] + tld;
                            this.setState({
                                inputDomain: newDomain
                            })
                        }}/>
                    </Col>
                </Row>
            </div>
            {typeof this.state.whoisResult === "boolean" &&
            <div ref={(div) => this.whoisResultDiv = div} className={classnames("whois-result bg-opacity-75 p-5", {
                "bg-not-available": !this.state.whoisResult,
                "bg-available": this.state.whoisResult,
                // "bg-danger": !this.state.whoisResult
            })}>
                <Container>
                    {this.state.whoisResult === true &&
                    <WhoisResultAvailable domain={this.state.whoisDomain} urlForPurchase={this.state.registerUrl}
                                          priceForPurchase={Price}/>}
                    {this.state.whoisResult === false &&
                    <WhoisResultNotAvailable
                        whoisRegistrant={(cb) => {
                            this.whoisProcess(this.state.inputDomain || this.initDomain, false, true, cb);
                        }}
                        queryDuring={this.state.queryDuring}
                        forceRefresh={() => {
                            this.whoisProcess(this.state.whoisDomain, true);
                        }} domain={this.state.whoisDomain} data={this.state.whoisData}/>}
                </Container>
            </div>}
        </>
    }
}

export default HomeWhois