import React from "react";
import logo from "./../assets/img/reg.svg"
import grilogo from "./../assets/img/gri.svg"
import {Badge, Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import {FiFacebook, FiLinkedin, FiTwitter} from "react-icons/all";

class Footer extends React.Component {
    render() {
        return <div className={"footer bg-dark text-white pt-5"}>
            <Container>
                <Row>
                    <Col className={"footer-widget"}>
                        <h5>reg ® Hakkında</h5>
                        <p>reg.com.tr, kullanıcılarına .tr uzantılı domain ihtiyaçları başta olmak üzere, diğer web hizmetlerini en uygun fiyatlara sunan bir web girişimidir.</p>
                        <a href={"https://www.facebook.com/gridotnet"} target={"_blank"} rel={"noreferrer"} className={"btn btn-outline-light m-1"}><FiFacebook /></a>
                        <a href={"https://twitter.com/gridotnet"} target={"_blank"} rel={"noreferrer"} className={"btn btn-outline-light m-1"}><FiTwitter /></a>
                        <a href={"https://linkedin.com/company/gri"} target={"_blank"} rel={"noreferrer"} className={"btn btn-outline-light m-1"}><FiLinkedin /></a>
                        <div className={"w-100 d-block pt-3"}>
                            <Badge className={"bg-primary"}>&copy; {new Date().getFullYear()}</Badge>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col className={"footer-widget"}>
                                <h5>.tr Domain</h5>
                                <Nav className={"footer-nav"}>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/domain-sorgulama"}>Domain Sorgulama</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/toplu-domain-sorgulama"}>Toplu Domain Sorgulama</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/domain-transfer"}>Domain Transfer</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/domain-fiyatlari"}>Domain Fiyatları</NavLink></NavItem>
                                </Nav>
                            </Col>
                            <Col className={"footer-widget"}>
                                <h5>Destek</h5>
                                <Nav className={"footer-nav"}>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/iletisim.php"}>İletişim</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/submitticket.php"}>Destek Talebi Oluştur</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/gerekli-evraklar.php"}>Gerekli Evraklar</NavLink></NavItem>
                                </Nav>
                            </Col>
                            <Col className={"footer-widget"}>
                                <h5>reg®</h5>
                                <Nav className={"footer-nav"}>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/hakkimizda"}>reg® hakkında</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/kurumsal-bilgiler"}>Kurumsal Bilgiler</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/kullanim-sozlesmesi"}>Kullanım Sözleşmesi</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/gizlilik-sozlesmesi"}>Gizlilik Sözleşmesi</NavLink></NavItem>
                                    <NavItem><NavLink href={"https://www.reg.com.tr/iade-sartlari"}>İade Şartları</NavLink></NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div className={"footer-last d-flex bg-light text-dark p-2 mt-4"}>
                <div className="text-center w-100 pt-2">
                    <p>
                        <img src={logo} height={20} alt={"reg-logo"}/> bir <a href={"https://www.gri.net"}
                                                                              rel={"noreferrer"} target={"_blank"}><img
                        src={grilogo} height={20} alt={"gri-logo"}/></a> iştirakidir.
                    </p>
                </div>
            </div>
        </div>
    }
}

export default Footer