import React from "react";
import {Button, Col, Row, UncontrolledTooltip} from "reactstrap";
import {BiChevronRight, FaLongArrowAltRight, FiInfo, HiBadgeCheck, IoReload, VscQuestion} from "react-icons/all";
import {Animated} from "react-animated-css";
import punycode from "punycode";

export class QueryAgainButton extends React.Component {
    render() {
        return <>
            <p className={"d-none d-lg-block mt-3"}>&nbsp;</p>
            <p className={"d-none d-lg-block mt-3"}>&nbsp;</p>
            <p className={"mt-3 text-center"}>
                <Button
                    onClick={() => {
                        window.postMessage({
                            command: "refresh_query"
                        }, "*");
                    }}
                    size={"sm"} color={"dark"}>Yeni Bir Domain Sorgula <IoReload size={21}/></Button>
            </p>
            <p className={"d-block d-lg-none mt-3"}>&nbsp;</p>
        </>;
    }
}

class WhoisResultAvailable extends React.Component {
    render() {
        let {domain, urlForPurchase, priceForPurchase} = this.props
        let idnDomain = domain === punycode.toASCII(domain) ? false : punycode.toASCII(domain);
        return <Row>
            <Col className={"text-center"}>
                <h2 className={"text-white "}><HiBadgeCheck/> <strong
                    style={{textDecoration: "underline"}}>{domain}</strong> müsait!</h2>
                {idnDomain ? <>
                    <UncontrolledTooltip target={"idn-domain-info"}>
                        <strong>IDN Domain</strong>
                    </UncontrolledTooltip>
                    <span className={"text-dark d-block"}><FiInfo id={"idn-domain-info"} /> {idnDomain}</span></> : ""}
                {urlForPurchase &&
                <a rel={"noreferrer"}
                   href={urlForPurchase || "#"}
                   target={"_blank"}
                   className={"btn btn-primary mt-3 text-white"}
                >Şimdi Kaydet {priceForPurchase ? `₺${priceForPurchase}*` : ""}
                    <FaLongArrowAltRight style={{marginTop: -5, marginLeft: 10, marginRight: 5}}/>
                </a>}
                {priceForPurchase && <p className={"text-white opacity-75"} style={{fontStyle: "italic", fontSize: 12}}>*Vergiler
                    hariç</p>}
                <QueryAgainButton/>
            </Col>
            <Col>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"bg-white rounded p-3 shadow mb-3"}>
                        <strong>Domaini kaydederek ne şekilde kullanabilirim?</strong>
                        <p>Kaydedeceğiniz domain (internet alan adı) ile bir web sitesi oluşturabilir, e-posta adresinizin uzantısı olarak kullanabilirsiniz.</p>
                    </div>
                </Animated>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"bg-white rounded p-3 shadow"}>
                        <strong>Bu domaini satabilir miyim?</strong>
                        <p>Satış ve devrinde kısıt bulunmayan uzantılarda domaini kaydederek farklı bir kişi/kuruma satabilirsiniz. Domaini bir yatırım aracı olarak kullanabilirsiniz.</p>
                    </div>
                </Animated>
                <Animated animationIn={"fadeInUp"}>
                    <div className={"d-flex p-3"}>
                        <h5 className={"w-100 text-white mt-3"} style={{lineHeight: "30px"}}><VscQuestion/> Diğer sorularınız için <Button color={"dark text-nowrap"} onClick={() => {
                            window.open('https://www.reg.com.tr/iletisim.php', '_blank');
                            return false;
                        }}>Bize Ulaşın
                            <BiChevronRight style={{marginTop: -3}}/></Button></h5>
                    </div>
                </Animated>
            </Col>
        </Row>
    }
}

export default WhoisResultAvailable