import React from "react";
import {
    Button, Col,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner,
    UncontrolledTooltip
} from "reactstrap";
import {
    BiCalendarAlt, BiLock,
    BiRefresh, BiTime, HiOutlineIdentification,
    IoMdBusiness, MdDns,
    TiWarning
} from "react-icons/all";
import {Animated} from "react-animated-css";
import moment from "moment-timezone"
import {QueryAgainButton} from "./WhoisResultAvailable";
import classnames from "classnames";
import {toast} from "react-toastify";

class WhoisResultNotAvailable extends React.Component {
    state = {
        data: this.props.data || {},
        domain: this.props.domain || "",
        registrantLoading: false,
        registrantLoaded: false,
        showRaw: false,
        rawData: "",
        rawUpdated: 0,
        rawLoading: true,
        lockedIcon: false
    }

    constructor(props) {
        super(props);
        this.rawToggle = this.rawToggle.bind(this);
    }


    componentDidMount() {
        let self = this;
        window.addEventListener('message', function (ev) {
            let {data} = ev;
            if (data.command === "registrant_data_loaded") {
                self.setState({registrantLoaded: true})
            }
        });
    }

    rawToggle = () => {
        this.setState({
            showRaw: !this.state.showRaw
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data)
            this.setState({
                data: nextProps.data,
                domain: nextProps.domain,
                registrantLoading: false,
                registrantLoaded: false
            })
    }

    render() {
        let {queryDuring} = this.props
        let {data, domain} = this.state

        if (typeof data.registrant === "undefined" || data.registrant === null)
            data.registrant = {}

        if (typeof data.lastRequest === "string" && data.lastRequest && (data.lastRequest).indexOf('+') === -1)
            data.lastRequest += '+00:00';
        return <Row>
            <div className={"text-center col-12 col-lg-6"}>
                <p className={"d-none d-lg-block"}>&nbsp;</p>
                <p className={"d-none d-lg-block"}>&nbsp;</p>
                <h2 className={"text-white "}><TiWarning/> <strong
                    style={{textDecoration: "underline"}}>{domain}</strong> müsait değil!</h2>
                <div className={""}>
                    <p style={{fontSize: 18}} className={"text-white opacity-75"}>
                        Son Güncelleme: {moment(data.lastRequest).subtract(1, "second").locale("tr").fromNow()}
                    </p>
                    <UncontrolledTooltip placement={"right"} target={"force-refresh"}>Şimdi
                        Güncelle</UncontrolledTooltip>
                    <Button id={"force-refresh"}
                            disabled={queryDuring}
                            onClick={() => {
                                if (typeof this.props.forceRefresh === "function")
                                    this.props.forceRefresh();
                            }}
                            style={{width: 32, height: 32, padding: 0, lineHeight: "10px", marginLeft: 15}}
                            className={classnames("rounded-circle", {
                                "spin-animation": queryDuring
                            })} color={"light"}><BiRefresh/></Button>
                </div>
                <QueryAgainButton/>
            </div>
            <div className={"col-12 col-lg-6"}>
                <Animated animationIn={"fadeInUp"}>
                    <h3 className={"text-white"}>Domain Bilgileri</h3>
                    <div className={"bg-white rounded p-3 shadow mb-3"}>
                        <p><strong>Kayıt Tarihi: </strong> {moment(data.createdOn).format("DD/MM/YYYY")}</p>
                        <p><strong>Bitiş Tarihi: </strong> {moment(data.expiresOn).format("DD/MM/YYYY")}</p>
                        <p><strong>Yaşı: </strong> {data.domainAge}</p>
                        {typeof data.domainStatus !== "undefined" && data.domainStatus !== null &&
                        <p><strong>Durum Kodu: </strong>
                            <ul style={{display: "inline-table"}}>
                                {(data.domainStatus).map(item => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </p>}
                        <hr/>
                        <h5><MdDns/> İsim Sunucuları</h5>
                        {(data.domainServers || []).map(item => (typeof item === "string" && item) ?
                            <p><strong>-</strong> {item}</p> : null)}
                        <hr/>
                        <h5><IoMdBusiness/> Kayıt Şirketi</h5>
                        {data.registrar !== null ? (typeof data.registrar === "string" ? data.registrar : (typeof data.registrar === "object" && typeof data.registrar.Name ? data.registrar.Name : "")) : ""}
                    </div>
                    <div className={classnames("text-center p-3 mb-3")}>
                        <Button
                            className={classnames({
                                "d-none": this.state.registrantLoaded
                            })}
                            onClick={() => {
                                this.loadRegistrantDetails()
                            }} color={"primary"}>Sahip Bilgilerini Göster {this.state.registrantLoading && <div
                            className={"spinner-grow spinner-grow-sm"}/>}</Button>
                        &nbsp;
                        <Button onClick={() => {
                            this.rawToggle()
                        }}>Tam WHOIS Çıktısı</Button>
                    </div>
                    <div className={classnames("bg-white rounded p-3 shadow mb-3", {
                        "d-none": !this.state.registrantLoaded
                    })}>
                        <h5><HiOutlineIdentification/> Sahip Bilgileri</h5>
                        {typeof data.registrant === "object" && <>
                            <ListGroup>
                                {typeof data.registrant.Name === "string" && data.registrant.Name !== "" &&
                                <ListGroupItem><strong>İsim:</strong> {data.registrant.Name}</ListGroupItem>}
                                {typeof data.registrant.Organization === "string" && data.registrant.Organization !== "" &&
                                <ListGroupItem><strong>Firma:</strong> {data.registrant.Organization}</ListGroupItem>}
                                {typeof data.registrant.Address === "object" && Object.keys(data.registrant.Address).length > 0 &&
                                <ListGroupItem><strong>Adres:</strong> {data.registrant.Address.join(" ")}
                                </ListGroupItem>}
                                {typeof data.registrant.TelephoneNumber !== "undefined" && data.registrant.TelephoneNumber !== "" &&
                                <ListGroupItem><strong>Telefon:</strong> {data.registrant.TelephoneNumber}
                                </ListGroupItem>}
                            </ListGroup>
                        </>}
                    </div>
                </Animated>
            </div>
            <Modal size={"lg"} toggle={this.rawToggle} isOpen={this.state.showRaw} onOpened={() => {
                this.loadRawData();
            }} onClose={() => {
                this.clearRawData();
            }}>
                <ModalHeader>{this.state.domain} - Tam WHOIS Çıktısı {this.state.lockedIcon ? <><BiLock
                    id={"transfer-lock"}/>
                    <UncontrolledTooltip placement={"right"} target={"transfer-lock"}>Transfer kilidi:
                        AKTİF</UncontrolledTooltip>
                </> : ""}</ModalHeader>
                <ModalBody className={"bg-light"}>{this.state.rawLoading ? <div className={"text-center mt-3"}>
                        <Spinner type={"grow"} size={"sm"}/>
                    </div> :
                    <pre style={{height: 500}}>{this.state.rawData}</pre>}
                </ModalBody>
                <ModalFooter>
                    <div className={"d-flex w-100"}>
                        <div className={"w-100 text-nowrap"}>
                            Son Güncelleme <Button
                            onClick={() => {
                                this.loadRawData(true)
                                if (!this.state.reloadAvailable)
                                    toast.error("30 dakikada 1 yenileme yapabilirsiniz.");
                            }}
                            style={{width: 32, height: 32, lineHeight: "12px"}}
                            className={"p-0 rounded-circle border"}
                            color={"light"}><BiRefresh/></Button><br/><small><BiTime/> {moment.unix(this.state.rawUpdated).fromNow()}
                        </small>
                        </div>
                        <div className={"w-100 text-right"}>
                            <Button onClick={this.rawToggle} color={"danger"}>Kapat</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </Row>
    }

    loadRegistrantDetails() {
        this.setState({registrantLoading: true})
        let callback = () => {
            this.setState({registrantLoading: false})
        }
        if (typeof this.props.whoisRegistrant === "function")
            this.props.whoisRegistrant(callback)
    }

    loadRawData(forceRefresh) {
        let raw_base = process.env.REACT_APP_WHOISRAW_BASE_URL,
            url = `${raw_base}/whois/${this.state.domain}`
        ;
        this.setState({rawLoading: true})
        if (typeof forceRefresh === "boolean" && forceRefresh)
            url += '[force]';

        fetch(url).then(r => r.json())
            .then(r => {
                this.setState({
                    rawData: r.raw,
                    rawUpdated: r.cache_time,
                    reloadAvailable: (r.now - r.cache_time) >= (r.force_reload_time),
                    rawLoading: false,
                    lockedIcon: ((this.state.domain).indexOf(".tr") > -1 && (r.raw).indexOf("The domain is LOCKED to") > -1)
                })
            })
    }

    clearRawData() {
        this.setState({rawLoading: true});
    }
}

export default WhoisResultNotAvailable