import React from "react";
import {Card, CardBody, CardHeader, Collapse, Container} from "reactstrap";
import classnames from "classnames";
import {getSiteConfig} from "../App";

class HomeFaq extends React.Component {
    state = {
        opened: "0"
    }

    render() {

        const questions = getSiteConfig("faq");

        return <div className={"p-2 p-lg-5 bg-gradient"}>
            <div className={"text-center pb-5"}>
                <h3>Sık Sorulan Sorular</h3>
            </div>
            <Container>
                {questions.map(item => <Card className={"mb-1"}>
                    <CardHeader onClick={() => {
                        if (this.state.opened !== item.key)
                            this.setState({opened: item.key})
                        else
                            this.setState({opened: null})

                    }} className={classnames("cursor-pointer", {
                        "bg-dark": this.state.opened === item.key,
                        "text-white": this.state.opened === item.key
                    })} color={"secondary"}>
                        {item.question}
                    </CardHeader>
                    <Collapse isOpen={this.state.opened === item.key}>
                        <CardBody>
                            {item.answer}
                        </CardBody>
                    </Collapse>
                </Card>)}
            </Container>
        </div>
    }
}

export default HomeFaq