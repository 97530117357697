import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Header from "./components/Header";
import HomeWhois from "./components/HomeWhois";
import HomeFaq from "./components/HomeFaq";
import Footer from "./components/Footer";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "moment/locale/tr";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export const site_title = "WHOIS ([domain]) - reg.com.tr"
export const set_site_title = (domain) => {
    let title = site_title + '';
    if (domain === null)
        domain = ""
    title = title.replace("[domain]", domain);
    title = title.replace("()", "");
    document.title = title;
}
export const get_site_slug = () => {
    return (process.env.REACT_APP_SITE_SLUG) || "default";
}

export const getSiteConfig = (configName) => {
    let siteSlug = get_site_slug();
    let config = require(`./config/${siteSlug}`);
    if (typeof config.config === "object")
        config = config.config;
    return typeof config[configName] !== "undefined" ? config[configName] : "-";
}

function App() {
    return (
        <Router>
            <div className={"App site-"+getSiteConfig("slug")}>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5 * 1000}
                    closeOnClick
                />
                <Header/>
                <Switch>
                    <Route exact path={'/'}><HomeWhois/></Route>
                    <Route path="/:domain" render={(props) => {
                        return <HomeWhois {...props}/>
                    }}/>
                </Switch>
                <HomeFaq/>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
