export const config = {
    "slug": "reg",
    "home-title": "Reg - Whois Sorgulama",
    "home-description": "Domain sahip bilgilerini ve müsaitlik durumunu sorgulayın.",
    "top-menu": [
        {
            url: "https://www.reg.com.tr/domain-sorgulama",
            label: "Domain Kayıt"
        }, {
            url: "https://www.reg.com.tr/marka/online-marka-tescili",
            label: "Marka Tescili"
        }, {
            url: "https://www.reg.com.tr/domain-transfer",
            label: "Domain Transfer"
        }, {
            url: "https://www.reg.com.tr/domain-fiyatlari",
            label: "Domain Fiyatları"
        }, {
            url: "https://www.reg.com.tr/clientarea.php",
            label: "Müşteri Paneli",
            mobileOnly: true
        }
    ],
    "faq": [
        {
            key: "0",
            question: "Sorguladığım domainler müsait değil, daha önceden kaydedilmiş. Ne yapabilirim?",
            answer: <>
                <p>Sorguladıktan sonra dolu olarak görüntülediğiniz domainler için, domain sahibine ulaşarak domaini satın almayı deneyebilir veya domainin süresi bitmesini bekleyip boşa düşmesi halinde satın alabilirsiniz.</p>
            </>
        },
        {
            key: "1",
            question: "Bir web sitesi sahibi olmak istiyorum. Domaini kaydettikten sonra ne yapmam gerekiyor?",
            answer: <>
                <p>Domainler web sitelerinin isimleridir. Domain kaydettiğinizde web sitenizin sadece ismini
                    belirlemiş olursunuz.</p>
                <p>Web sitenizi yayına almak için domain kayıt işleminden sonra bir web hosting paketi satın almalı
                    ve akabinde sitenizin tasarım &amp; yazılım işlemlerini yapmalı veya bir uzmandan destek
                    almalısınız.</p>
                <p>Konuyla ilgili desteğe ihtiyacınız varsa <a href={"tel:+908508404060"}>0850 840 40 60</a> nolu
                    telefondan veya <a rel={"noreferrer"} href={"https://www.gri.net/submitticket.php"}
                                       target={"_blank"}>burdan</a> destek talebi
                    açarak uzmanlarımızla görüşebilirsiniz.</p>
            </>
        },
        {
            key: "2",
            question: "Belge gerekmeden hangi .tr uzantılarıyla domain kaydedebilirim?",
            answer: <>
                <p><strong>com.tr</strong>, <strong>net.tr</strong>, <strong>org.tr</strong>, gen.tr, web.tr, name.tr, info.tr, biz.tr, bbs.tr uzantılı domainleri, herhangi bir belge
                    gerekmeden kaydedebilirsiniz.</p>
            </>
        }
    ]
}